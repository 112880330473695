import {ViewController} from "data/types/structure";
import type {IRound} from "data/providers/json/json.provider";

import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IGamebarStore} from "data/stores/gamebar/gamebar.store";
import {SelectChangeEvent} from "@mui/material";

export interface IRoundSelectorController extends ViewController {
	onRoundChange: (e: SelectChangeEvent<number>) => void;
	setActualRound: (roundId: number) => void;
	openSelect: () => void;
	closeSelect: () => void;

	get selectedRound(): IRound | undefined;

	get availableRounds(): IRound[];

	get isSelectOpen(): boolean;

	get isSelectorVisible(): boolean;
}

@injectable()
export class RoundSelectorController implements IRoundSelectorController {
	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.GamebarStore) private _gamebarStore: IGamebarStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isSelectOpen: boolean = false;

	get isSelectOpen(): boolean {
		return this._isSelectOpen;
	}

	get selectedRound(): IRound | undefined {
		return this._roundsStore.selectedRound || this._roundsStore.currentRound;
	}

	get isSelectorVisible() {
		return this._teamStore.team.startRoundId !== null;
	}

	get availableRounds(): IRound[] {
		const currentRoundId = this._roundsStore.currentRound?.id || 0;
		const teamStartRound = this._teamStore.team.startRoundId || 0;

		return this._roundsStore.list.filter(
			(e) => e.id <= currentRoundId && e.id >= teamStartRound
		);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	setActualRound(roundId: number): void {
		const round = this._roundsStore.getRoundById(roundId);
		if (!round) return;

		if (round.id === this._roundsStore.selectedRound?.id) return;

		this._teamStore.clearSwaps();
		this._teamStore.clearTrades();
		this._roundsStore.selectedRound = round;
		this._teamStore.fetchTeamForSelectedRound();
		this._gamebarStore.fetchGamebarForSelectedRound();
	}

	onRoundChange = (event: SelectChangeEvent<number>) => {
		const {value} = event.target;
		this.setActualRound(Number(value));
	};

	public openSelect = () => {
		runInAction(() => {
			this._isSelectOpen = true;
		});
	};

	public closeSelect = () => {
		runInAction(() => {
			this._isSelectOpen = false;
		});
	};
}

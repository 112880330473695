import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {ITeam} from "data/types/team";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";
import {makeAutoObservable, toJS} from "mobx";
import {getDashedValue} from "data/utils";
import {TeamPosition} from "data/enums";

export interface IFieldController extends ViewController {
	get team(): ITeam;
	get teamLineUp(): Record<TeamPosition, number[]>;
	getRegionById(id: number): string;
	get regionGroup(): string[];
	get wasatReserveId(): number;
	get isLoading(): boolean;
	get displayDriver(): number;
}

@injectable()
export class FieldController implements IFieldController {
	constructor(@inject(Bindings.TeamStore) private _teamStore: ITeamStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	get team(): ITeam {
		return this._teamStore.team;
	}

	get teamLineUp(): Record<TeamPosition, number[]> {
		return this._teamStore.teamLineUp;
	}

	get regionGroup(): string[] {
		return toJS(this._teamStore.regionGroups);
	}

	get isLoading(): boolean {
		return !this._teamStore.isTeamLoaded;
	}

	public getRegionById(id: number): string {
		return getDashedValue(this._teamStore.getRegionGroupById(id));
	}

	public get wasatReserveId(): number {
		const indexToCheck = [4, 5, 6];
		const value = indexToCheck.find((key) => this._teamStore.team.lineup.reserve[key] !== 0);
		const idx = value ? value : 0;
		return this._teamStore.reserveId || idx;
	}

	get displayDriver(): number {
		const indexToCheck = [4, 5, 6];
		const value = indexToCheck.find((key) => this._teamStore.team.lineup.reserve[key] !== 0);
		return value !== undefined ? this._teamStore.team.lineup.reserve[value] : 0;
	}
}

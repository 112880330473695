import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, action} from "mobx";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IModalMessage, TModalArguments} from "data/types/modals";
import {Bindings} from "data/constants/bindings";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {IFullTrade, IUserTrade} from "data/types/team";

export interface IModalsController extends ViewController {
	show: (...args: TModalArguments) => void;
	close: () => void;

	get user(): IUser | undefined;

	get visibleModal(): ModalType | null;

	get modalContent(): IModalMessage | unknown | null;

	get isTeamNotSaved(): boolean;

	deactivate: () => void;

	get trades(): IFullTrade[];

	get madeTrades(): IFullTrade[];

	makeTrades: () => void;

	resetTrades: () => void;

	get tradesLeft(): number;

	get totalTrades(): number;

	get budgetLeft(): number;

	get isOverBudget(): boolean;
}

@injectable()
export class ModalsController implements IModalsController {
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	get user(): IUser | undefined {
		return this._userStore.user;
	}

	get visibleModal() {
		return this._modalsStore.modal;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	get isTeamNotSaved() {
		return this._teamStore.hasTeamBeenChanged;
	}

	show = (...args: TModalArguments) => {
		this._modalsStore.showModal(...args);
	};

	@action deactivate = () => {
		void this._userStore.deactivate();
		this.close();
	};

	close = () => {
		this._modalsStore.hideModal();
	};

	get trades() {
		return this._teamStore.teamFullTrades;
	}

	get madeTrades() {
		return this._teamStore.madeTradeDrivers;
	}

	private calculatePartialTradesCost(): number {
		const partialTrades = this._teamStore.teamPartialTrades;

		return partialTrades.reduce((total: number, trade: IUserTrade) => {
			if (trade.tradeIn) {
				total += trade.tradeIn.cost;
			}

			if (trade.tradeOut) {
				total -= trade.tradeOut.cost;
			}

			return total;
		}, 0);
	}

	makeTrades = () => {
		this._teamStore.makeTrades();
		this.close();
	};

	resetTrades = () => {
		this._teamStore.resetMadeTrades();
		this.close();
	};

	get tradesLeft() {
		return this._teamStore.tradesLeft;
	}

	get totalTrades() {
		return this._teamStore.totalTrades;
	}

	get budgetLeft() {
		const baseSalaryCap = this._teamStore.salaryCap;

		const partialTradesImpact = this.calculatePartialTradesCost();

		return baseSalaryCap + partialTradesImpact;
	}

	get isOverBudget() {
		return this.budgetLeft < 0;
	}

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}

import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type ITeamStore} from "data/stores/team/team.store";
import {makeAutoObservable} from "mobx";
import {IUserTrade} from "data/types/team";
import {type IDriverStore} from "data/stores/driver/drivers.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";

export interface ITradeBarController extends ViewController {
	get isVisible(): boolean;
	get isResetMadeTradesAvailable(): boolean;
	get trades(): IUserTrade[];
	get madeTrades(): IUserTrade[];
	get isLockout(): boolean;
	get displayReset(): boolean;
	get selectedRoundId(): number | undefined;
	get isSelectedRoundCurrent(): boolean;
	resetTrade: (index: number) => void;
	resetTrades: () => void;
}

@injectable()
export class TradeBarController implements ITradeBarController {
	get isVisible(): boolean {
		return this.trades.length > 0 || this._teamStore.team.trades.length > 0;
	}

	constructor(
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.DriverStore) private _driverStore: IDriverStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	get trades() {
		return this._teamStore.userTrades;
	}

	get madeTrades(): IUserTrade[] {
		return this._teamStore.madeTradeDrivers;
	}

	get isResetMadeTradesAvailable() {
		return this.madeTrades.length > 0 && this.trades.length === 0;
	}
	get isSelectedRoundCurrent() {
		return this._roundsStore.isSelectedRoundCurrent;
	}

	get selectedRoundId() {
		return this._roundsStore.selectedRound?.id;
	}
	get displayReset() {
		return this.isSelectedRoundCurrent ? !this.isLockout : false;
	}

	public get isLockout() {
		return this._teamStore.isTeamInLockout;
	}

	public resetTrade = (index: number): void => {
		void this._teamStore.resetTrade(index);
	};

	public resetTrades = (): void => {
		void this._teamStore.resetTrades();
	};
}

import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, computed, makeAutoObservable, observable} from "mobx";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {ITitleStore} from "data/stores/title/title.store";
import {RoundStatus} from "data/enums";
import {IRank} from "data/providers/api/rankings.api.provider";
import {cloneDeep, first} from "lodash";
import {type IRankingsStore} from "data/stores/rankings/rankings.store";
import {IRound} from "data/providers/json/json.provider";

export interface IRankingsController extends ViewController {
	get isScoringStarted(): boolean;
	fetchRankings: () => void;
	get userRank(): IRank | null;
	get rankingsUsers(): IRank[];
	get isUserRowSticky(): boolean;
	get isRequestInProgress(): boolean;
	get rounds(): IRound[];
	get selectRounds(): IRound[];
	get nextPage(): boolean;
	loadMore: () => void;
	get selectedRound(): number;
	handleRoundChange: (round: number) => void;
	get rankKey(): string;
	get pointsKey(): string;
}

@injectable()
export class RankingsController implements IRankingsController {
	@observable page = 1;
	@observable _selectedRound = 0;

	get nextPage() {
		return this._rankingsStore.nextPage;
	}

	get rankingsUsers(): IRank[] {
		return this._rankingsStore.rankingsUsers;
	}

	get userRank(): IRank | null {
		return this._rankingsStore.userRank;
	}

	get rounds() {
		return this._roundsStore.list;
	}

	get selectedRound() {
		return this._selectedRound;
	}

	get selectRounds() {
		console.log(cloneDeep(this.rounds));
		return this.rounds.filter((round) => {
			if (round.status === RoundStatus.Playing) {
				return round.races[0].status !== RoundStatus.Scheduled;
			}

			return round.status === RoundStatus.Complete;
		});
	}

	get userId() {
		return this._userStore.user?.id || 1;
	}

	get isRequestInProgress() {
		return this._rankingsStore.isRequestInProgress;
	}

	@computed
	get isScoringStarted(): boolean {
		const startRound = first(this._roundsStore.list);
		if (!startRound) {
			return false;
		}
		if (startRound.status === RoundStatus.Playing) {
			return startRound.races[0].status === RoundStatus.Complete;
		}
		return startRound.status === RoundStatus.Complete;
	}

	get isUserRowSticky(): boolean {
		return !this.rankingsUsers?.some((e) => e.userId === this.userId);
	}

	constructor(
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TitleStore) private _titleStore: ITitleStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@action handleRoundChange = (round: number) => {
		this._selectedRound = round;
		this.fetchRankings();
	};

	@action fetchRankings = () => {
		this._rankingsStore.fetchRankings(this.selectedRound);
	};

	@action loadMore = () => {
		this._rankingsStore.loadMore(this.selectedRound);
	};

	dispose(): void {
		return;
	}

	init(): void {
		void this._titleStore.setTitle("Standings");
		void this._roundsStore.fetchRounds();
	}

	get rankKey(): string {
		return this.selectedRound ? "rank" : "overallRank";
	}

	get pointsKey(): string {
		return this.selectedRound ? "points" : "overallPoints";
	}
}

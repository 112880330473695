import {action, makeAutoObservable, observable, runInAction, toJS} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider, IRound, IDropdownRound} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {AxiosError} from "axios";
import {IAxiosApiError} from "data/types/global";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {H2HFinalType, RoundStatus} from "data/enums";
import {first, get, last} from "lodash";
import {FINALS_NAME} from "data/constants";
import {ILeague} from "data/providers/api/league.api.provider";

export interface IRoundsStore {
	get isRoundsLoaded(): boolean;

	get getIsLoading(): boolean;

	get list(): IRound[];

	get scheduledRounds(): IRound[];

	get currentRound(): IRound | undefined;

	get isSelectedRoundCurrent(): boolean;

	get scoreRound(): IRound | null;

	get isTradesRound(): boolean;

	get isPreseason(): boolean;

	get selectedRound(): IRound | undefined;

	set selectedRound(round: IRound | undefined);

	get isLockout(): boolean;

	getRoundById(roundId: number | undefined): IRound | undefined;

	fetchRounds(withCheck?: boolean): Promise<void>;

	h2hLeagueRounds(finalType: H2HFinalType | undefined): IRound[];

	getIsH2hDisabled(finalType: H2HFinalType | undefined): boolean;

	prepareRoundForDropdown(round: IRound, league?: ILeague): IDropdownRound;
}

@injectable()
export class RoundsStore implements IRoundsStore {
	@observable private _isLoading: boolean = false;

	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _selectedRound: IRound | undefined;

	get selectedRound(): IRound | undefined {
		return this._selectedRound || this.currentRound;
	}

	set selectedRound(value: IRound | undefined) {
		this._selectedRound = value;
	}

	@observable private _list: IRound[] = [];

	get list() {
		return toJS(this._list);
	}

	get scheduledRounds() {
		return this._list.filter((e) => e.status === RoundStatus.Scheduled);
	}

	get completedRounds() {
		return this._list.filter((e) => e.status === RoundStatus.Complete);
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	get isRoundsLoaded(): boolean {
		return !this._isLoading && this._list.length > 0;
	}

	get isSelectedRoundCurrent() {
		const selectedRound = toJS(this.selectedRound);
		const currentRound = toJS(this.currentRound);

		return currentRound?.id === selectedRound?.id;
	}

	get currentRound() {
		return this.activeRound || first(this.scheduledRounds) || last(this.list);
	}

	get scoreRound() {
		return this.activeRound || last(this.completedRounds) || null;
	}

	get isTradesRound() {
		const selectedRound = toJS(this.selectedRound);
		const currentRound = toJS(this.currentRound);
		return (
			currentRound?.id === selectedRound?.id && currentRound?.status === RoundStatus.Scheduled
		);
	}

	get isPreseason() {
		const round = first(this.list);
		return round?.status === RoundStatus.Scheduled;
	}

	get isLockout() {
		const round = toJS(this.selectedRound);
		if (!round) {
			return false;
		}

		return round.status === RoundStatus.Playing;
	}

	private get activeRound() {
		return this.list.find((e) => e.status === RoundStatus.Playing);
	}

	@action
	async fetchRounds(withCheck?: boolean) {
		if ((this._list.length > 0 && withCheck) || this._isLoading) {
			return;
		}

		try {
			runInAction(() => {
				this._isLoading = true;
			});
			const {data} = await this._jsonProvider.rounds();
			runInAction(() => {
				this._list = data;
			});
		} catch (e) {
			const error = e as AxiosError<IAxiosApiError, unknown>;
			this._modalsStore.showAxiosError(error);
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	getIsH2hDisabled(finalType: H2HFinalType): boolean {
		const index = finalType === H2HFinalType.Top4 ? 3 : 4;
		return this.scheduleRoundsLength - index < 0;
	}

	getRoundById(roundId: number | undefined): IRound | undefined {
		return this.list.find((e) => e.id === roundId);
	}

	h2hLeagueRounds(finalType: H2HFinalType): IRound[] {
		const index = finalType === H2HFinalType.Top4 ? 3 : 4;
		const startIndex = this.getNotLessThenZeroValue(this.scheduledRounds.length - index);
		const rounds = [...this.scheduledRounds];
		rounds.splice(startIndex, index);
		return rounds;
	}

	public prepareRoundForDropdown(round: IRound, league?: ILeague): IDropdownRound {
		return {
			...round,
			name: this.prepareRoundName(round, league),
		};
	}

	private prepareRoundName(round: IRound, league?: ILeague): string {
		const defaultName = `Round ${round.id}`;
		if (!league || !league.finalsFormat) {
			return defaultName;
		}

		const finalType = league.finalsFormat;

		return get(FINALS_NAME, `${finalType}.${round.id}`, defaultName);
	}

	private get scheduleRoundsLength() {
		return this.scheduledRounds.length;
	}

	private getNotLessThenZeroValue(value: number) {
		return value < 0 ? 0 : value;
	}
}
